<template>
  <div class="project">
    <div class="titleBar">
      <PlateTitle
        :title="size === 'small' || size === 'large' ? '保费预缴' : '预缴'"
        class="plate"
      />
    </div>
    <!-- 可用余额 -->
    <div class="credit-block" v-if="size === 'small' || size === 'large'">
      <div class="left">
        <img src="../../assets/image/icon_ljqzje.png" alt="" />
        <div class="usemoney">
          <div class="title">可用余额(元)</div>
          <div class="money">{{ remainingAmount }}</div>
        </div>
      </div>
      <div class="right">
        <a-button type="primary" @click="showModal">去预缴</a-button>
      </div>
    </div>
    <!-- 预缴 -->
    <div class="prepay" v-if="size === 'prepay'">
      <a-form
        :form="form"
        layout="vertical"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="充值方式">
              <a-input disabled v-decorator="['recharge', { rules: [{}] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款开户行">
              <a-input disabled v-decorator="['bankName', { rules: [{}] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款开户行名称">
              <a-input
                disabled
                v-decorator="['accountName', { rules: [{}] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款开户行账号">
              <a-input
                disabled
                v-decorator="['accountNumber', { rules: [{}] }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="预缴金额(元)">
              <a-input
                @change="handleMoneyChange"
                type="number"
                v-decorator="[
                  'money',
                  {
                    rules: [
                      { required: true, message: '请输入预缴金额' }
                    ]
                  }
                ]"
                placeholder="请输入预缴金额"
              />
            </a-form-item>
            <div style="padding-left: 14px;    background: #f7f7f7;border: 1px solid #eaeaea;width: 360px; height: 44px;color: rgba(0, 0, 0, 0.25);line-height: 44px">{{ money }}</div>
          </a-col>
          <a-col :span="12"></a-col>
        </a-row>
        <div class="footer">
          <a-button class="pay-btn" type="primary" @click="handleClickShowPay">
            立即支付
          </a-button>
          <a-button class="cancle-btn" @click="handleClickBack">
            取消
          </a-button>
        </div>
      </a-form>
    </div>
    <!-- 预缴支付 -->
    <a-modal
      title="预缴支付"
      class="prepaymodal"
      :visible="showprepay"
      :footer="null"
      @ok="handleOk"
      @cancel="showprepay = false"
    >
      <a-form
        :form="formPay"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-form-item label="预缴金额（元）：">
          <span style="height: 32px;width: 200px;padding: 4px 11px;color: rgba(0, 0, 0, 0.25);background-color: #f5f5f5;cursor: not-allowed;border-color: #d9d9d9;border: 1px solid #d9d9d9;border-radius: 4px;line-height: 24px;display: inline-block;">{{ money }}</span>
          <!-- <a-input v-decorator="['amount', { rules: [{}] }]" disabled /> -->
        </a-form-item>
        <!-- <div style="padding-left: 14px;text-align:right">{{ money }}</div> -->
        <a-form-item label="打款凭证" v-if="showprepay">
          <a-upload
            name="file"
            :multiple="true"
            :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
            :data="{ fileUrl: 'entCertForm' }"
            v-decorator="[
              'file',
              { rules: [{ required: true, message: '请选择' }] }
            ]"
            accept=".png,.jpeg,.jpg"
            @change="handleChange"
          >
            <a-button class="upload-btn">
              <img
                style="width: 18px; height: 18px; margin-top: -4px"
                src="../../assets/image/icon_scwj.png"
                alt=""
              />
              <span style="color: #005bac; padding-left: 4px">上传文件</span>
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 10 }" class="entBtn">
          <a-button type="primary" @click="handleClickPay"> 提交 </a-button>
          <a-button @click="showprepay = false"> 取消 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 操作成功 -->
    <a-modal
      title="支付"
      class="prepaymodal"
      :visible="showsuccess"
      :footer="null"
      @ok="handleSuccess"
      @cancel="() => (showsuccess = false)"
    >
      <div class="handle-blok">
        <img src="../../assets/image/icon_cg.png" alt="" srcset="" />
        <div class="info">操作成功</div>
        <a-button type="primary" class="handle-btn" @click="handleClickEnter">
          确定
        </a-button>
      </div>
    </a-modal>
    <div class="tab" v-if="size === 'small' || size === 'large'">
      <a-radio-group v-model="size" style="margin-bottom: 16px">
        <a-radio-button value="small">使用记录 </a-radio-button>
        <a-radio-button value="large"> 预缴记录 </a-radio-button>
      </a-radio-group>
    </div>
    <!-- 使用记录 -->
    <div class="usehistory" v-if="size === 'small'">
      <div class="search">
        <a-row>
          <a-col :span="10">
            <span>交易类型</span>
            <a-select v-model="query.type" @change="handleClickType">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option
                v-for="item in statusList"
                :key="item.label"
                :value="item.value"
                dropdownStyle
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="10">
            <span>交易时间</span>
            <a-range-picker
              style="width: 250px"
              v-model="query.date"
              :locale="locale"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </a-col>
          <a-col :span="4" style="text-align: right">
            <a-button
              type="primary"
              style="background: #007ce2"
              @click="usetoQuery"
            >
              查询
            </a-button>
            <a-button @click="resetUse"> 重置 </a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="useData"
          :pagination="usePagination"
          @change="handleTableChange"
        >
          <span class="tabProject" slot="name" slot-scope="space">
            {{ space }}
          </span>
          <span slot="amount" slot-scope="space, text">
            {{ text.type === '1' || text.type === '3' ? '+' : '-' }}
            <span>{{ throundsHandle(text.amount) }}</span>
          </span>
          <span slot="type" slot-scope="space, text">
            {{ text.type === '2' ? '投保' : text.type === '3' ? '退保' : '' }}
          </span>
          <span slot="afterAmount" slot-scope="space, text">
            {{ throundsHandle(text.afterAmount) }}
          </span>
          <span slot="auditTime" slot-scope="space, text">
            {{ text.auditTime ? dateFormat(text.auditTime) : '' }}
          </span>
          <span slot="action" slot-scope="space, text">
            <a @click="view(text)">查看</a>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 预缴记录 -->
    <div class="prepayhistory" v-if="size === 'large'">
      <div class="search">
        <a-row>
          <a-col :span="10">
            <span>预缴单号</span>
            <a-input
              v-model="query.orderNo"
              placeholder="请输入"
            />
          </a-col>
          <a-col :span="10">
            <span>审核时间</span>
            <a-range-picker
              v-model="query.date"
              :locale="locale"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </a-col>
          <a-col :span="4" style="text-align: right">
            <a-button
              type="primary"
              style="background: #007ce2"
              @click="usetoQuery"
            >
              查询
            </a-button>
            <a-button @click="resetUse"> 重置 </a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table
          :columns="columnsYJ"
          :data-source="useData"
          :pagination="usePagination"
          @change="handleTableChange"
        >
          <span class="tabProject" slot="name" slot-scope="space">
            {{ space }}
          </span>
          <span slot="status" slot-scope="space, text">
            <span v-if="text.status === '1'">审核中</span>
            <span v-else-if="text.status === '2'">审核通过</span>
            <span v-else-if="text.status === '3'">审核不通过</span>
            <span v-else-if="text.status === '4'">财务审核中</span>
            <span v-else>text.status</span>
          </span>
          <span slot="amount" slot-scope="space, text">
            {{ throundsHandle(text.amount) }}
          </span>
          <span slot="afterAmount" slot-scope="space, text">
            {{ text.status === '1' ? '' : throundsHandle(text.afterAmount) }}
          </span>
          <span slot="auditTime" slot-scope="space, text">
            {{ text.auditTime ? dateFormat(text.auditTime) : text.auditTime }}
          </span>
          <span slot="action" slot-scope="space, text">
            <a @click="view(text)">查看</a>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import { dateFormat, throttle } from '@/utils/format.js';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import { getList, getRemainingAmount, getPayeeInfo, savePrepay } from '@/api/premiumDeposit.js';
export default {
  name: 'ProView',
  components: {
    PlateTitle,
  },
  data () {
    return {
      moneys: null,
      size: 'small',
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderNo',
          key: 'orderNo',
        },
        {
          title: '交易金额(元)',
          dataIndex: 'amount',
          key: 'amount',
          slots: { title: '交易金额(元)' },
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '交易类型',
          dataIndex: 'type',
          key: 'type',
          slots: { title: '交易类型' },
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '交易后金额(元)',
          dataIndex: 'afterAmount',
          key: 'afterAmount',
          slots: { title: '交易后金额(元)' },
          scopedSlots: { customRender: 'afterAmount' }
        },
        {
          title: '交易时间',
          dataIndex: 'auditTime',
          key: 'auditTime',
          slots: { title: '交易时间' },
          scopedSlots: { customRender: 'auditTime' }
        }
      ],
      columnsYJ: [
        {
          title: '预缴单号',
          dataIndex: 'orderNo',
          key: 'orderNo'
        },
        {
          title: '审核时间',
          dataIndex: 'auditTime',
          key: 'auditTime',
          slots: { title: '预缴时间' },
          scopedSlots: { customRender: 'auditTime' },
        },
        {
          title: '预缴金额(元)',
          dataIndex: 'amount',
          key: 'amount',
          slots: { title: '预缴金额(元)' },
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          key: 'status',
          slots: { title: '审核状态' },
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '可用余额(元)',
          dataIndex: 'afterAmount',
          key: 'afterAmount',
          slots: { title: '可用余额(元)' },
          scopedSlots: { customRender: 'afterAmount' },
        }
      ],
      VUE_APP_FIRST_URL: '',
      visible: false,
      typeList: [],
      statusList: [
        {
          label: '全部',
          value: '全部'
        },
        {
          label: '投保',
          value: '2'
        },
        {
          label: '退保',
          value: '3'
        }
      ],
      dateFormat,
      locale,
      title: {},
      orderList: [],
      loading: false,
      isPagination: false,
      pagination: {
        total: 0,
      },
      query: {
        page: 1,
        size: 10,
        type: null,
        startTime: null,
        endTime: null,
        operateType: '2'
      },
      useData: [],
      usePagination: {
        total: 0
      },
      remainingAmount: null,
      form: this.$form.createForm(this, {
        name: 'loginForm'
      }),
      formPay: this.$form.createForm(this, { name: 'payForm' }),
      showprepay: false,
      fileImg: [],
      showsuccess: false,
      money: ''
    };
  },
  mounted () {
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
    this.getTableList()
    this.query.type = '全部'
    this.info()
  },
  watch: {
    size () {
      this.useData = []
      this.usePagination.total = 0
      this.resetUse()
    },
  },
  methods: {
    handleMoneyChange (e) {
      this.money = e.target.value ? this.throundsHandle(e.target.value) : ''
      this.moneys = e.target.value
    },
    formatDecimal (num) {
      if (num) {
        return this.throundsHandle(parseFloat(Number((num / 10000).toFixed(5))))
      } else {
        return num
      }
    },
    handleClickType (e) {
      this.query.type = e
    },
    handleClickEnter () {
      this.showsuccess = false
      this.size = 'small'
      this.getTableList()
      this.info()
    },
    handleChange (info) {
      if (info.file.status === 'done') {
        if (info.fileList && info.fileList.length > 0) {
          this.fileImg = info.fileList.map(item => {
            return item.response.data.url
          })
        }
      }
    },
    info () {
      getRemainingAmount().then(res => {
        this.remainingAmount = res.data ? this.throundsHandle(res.data) : 0
      })
    },
    handleSubmit () { },
    handleOk () { },
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    resetUse () {
      this.query.page = 1
      this.query.size = 10
      this.query.type = '全部'
      this.query.date = null
      this.query.orderNo = null
      this.query.startTime = null
      this.query.endTime = null
      if (this.size === 'small') {
        this.query.operateType = '2'
      } else if (this.size === 'large') {
        this.query.operateType = '1'
      }
      this.getTableList()
    },
    usetoQuery () {
      this.query.page = 1
      this.query.size = 10
      if (this.size === 'small') {
        this.query.operateType = '2'
      } else if (this.size === 'large') {
        this.query.type = '全部'
        this.query.operateType = '1'
      }
      this.query.startTime = this.query.date ? this.query.date[0] : null
      this.query.endTime = this.query.date ? this.query.date[1] : null
      this.getTableList()
    },
    getTableList () {
      getList(this.query).then(res => {
        this.useData = res.data.content
        this.usePagination.total = res.data.totalElements
      })
    },
    /**
     * 操作失败
     */
    handleFail () {

    },
    /**
     * 操作成功
     */
    handleSuccess () {

    },
    handleClickPay: throttle(function () {
      this.formPay.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        const params = {
          amount: this.moneys,
          files: JSON.stringify(this.fileImg)
        }
        savePrepay(params).then(res => {
          this.showprepay = false
          this.showsuccess = true
        })
      })
    }, 3000),
    /**
     * 打开预缴支付
     */
    handleClickShowPay () {
      this.form.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        if (this.form.getFieldsValue().money == 0 || !this.form.getFieldsValue().money) {
          this.$message.error('预缴金额必须大于0')
          return
        }
        this.showprepay = true
        this.$nextTick(() => {
          this.formPay.setFieldsValue({
            amount: this.form.getFieldsValue().money,
            file: ''
          })
          this.fileImg = []
        })
      })
    },
    /**
     * 返回保费预缴
     */
    handleClickBack () {
      this.size = 'small'
    },
    showModal () {
      this.size = 'prepay'
      this.$nextTick(() => {
        getPayeeInfo().then(res => {
          this.form.setFieldsValue({
            recharge: '线下充值',
            bankName: res.data.bankName,
            accountName: res.data.accountName,
            accountNumber: res.data.accountNumber,
            amount: ''
          });
          this.money = ''
          this.moneys = null
        })
      })
    },
    // 页数改变
    handleTableChange (pagination) {
      this.query.page = pagination.current
      this.query.size = pagination.pageSize
      this.query.startTime = this.query.date ? this.query.date[0] : null
      this.query.endTime = this.query.date ? this.query.date[1] : null
      if (this.size === 'small') {
        this.query.operateType = '2'
      } else if (this.size === 'large') {
        this.query.operateType = '1'
      }
      this.getTableList()
    }
  },
};
</script>
<style lang="less" scoped>
.project {
  min-height: 666px;
  font-size: 14px;
}
.search {
  // display: flex;
  position: relative;
  margin-bottom: 30px;
  img {
    z-index: 99;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 39px;
    left: 10px;
  }
  /deep/ .ant-input-affix-wrapper {
    width: 340px;
  }
  /deep/ .ant-input {
    // width: 300px;
    width: 230px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    // margin: 30px 0 20px 0;
    margin: 0 0 20px 0;
    // padding-left: 40px;
  }
  .phase {
    margin: 30px 20px 0;
  }
  span {
    margin-right: 5px;
  }
  /deep/ .ant-select-selection {
    // margin-left: 5px;
    // width: 120px;
    width: 170px;
  }
  button {
    // margin-top: 30px;
    margin-right: 10px;
  }
}
.table {
  /deep/ .ant-list-empty-text {
    min-height: 500px;
    .ant-empty-normal {
      margin-top: 220px;
    }
  }
  /deep/ .ant-list-item {
    border-bottom: none;
    padding: 0 0 20px 0;
  }
  /deep/ .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border: none;
  }
  /deep/ .ant-list-pagination {
    margin: 0 0 8px 0;
  }
  .top {
    width: 920px;
    height: 80px;
    background: #f7faff;
    padding: 21px 41px 17px 32px;
    position: relative;
    border: 1px solid #ebf2f7;
    border-bottom: none;
    font-family: Microsoft YaHei;
    .top1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .top2 {
      font-size: 14px;
      font-weight: 400;
      color: #aaaaaa;
      position: absolute;
      right: 41px;
    }
    .top3 {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-top: 5px;
    }
  }
  .bottom {
    display: flex;
    width: 920px;
    background: #ffffff;
    border: 1px solid #ebf2f7;
    padding: 25px 40px 20px 31px;
    p {
      margin: 0;
    }
    .div1 {
      // width: 100px;
      width: 140px;
    }
    .div2 {
      width: 100px;
    }
    .div3 {
      width: 140px;
    }
    .div4 {
      width: 56px;
    }
    .div5 {
      width: 100px;
    }
    .div6 {
      width: 100px;
    }
    .div7 {
      width: 70px;
    }
    div {
      margin: 0 6px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        word-break: break-word; /* 文本行的任意字内断开 */
        word-wrap: break-word; /* IE */
        &:nth-child(1) {
          color: #aaaaaa;
        }
        &:nth-child(2) {
          color: #333333;
        }
      }
      a {
        margin-right: 10px;
      }
      .applyed {
        width: 88px;
        background: #b2d7f6;
        border: #b2d7f6;
      }
    }
  }
}
.credit-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  .left {
    display: flex;
    align-items: center;
    width: 49%;
    height: 100%;
    padding-left: 30px;
    background: #f7faff;
    border-radius: 3px 3px 3px 3px;
    img {
      width: 60px;
      height: 60px;
    }
    .usemoney {
      padding-left: 20px;
      color: #999999;
      .money {
        color: #333333;
        font-size: 30px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 49%;
    height: 100%;
    padding-right: 30px;
    background: #f7faff;
    border-radius: 3px 3px 3px 3px;
  }
  .ant-btn {
    width: 128px;
    height: 39px;
    font-size: 16px;
    font-weight: bold;
    background: #007ce2;
    border-radius: 5px 5px 5px 5px;
  }
}
.tab {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  .ant-radio-button-wrapper {
    width: 124px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #666666;
    font-size: 16px;
  }
  .ant-radio-button-wrapper-checked {
    color: #007ce2;
    font-weight: bold;
    border: 1px solid #007ce2;
  }
}
.search {
  color: #666666;
  padding-top: 20px;
  ::v-deep .ant-select-selection {
    width: 250px !important;
    height: 32px !important;
  }
  ::v-deep .ant-calendar-picker .ant-calendar-picker-input {
    width: 250px !important;
  }
}
.table {
  ::v-deep .ant-table-column-title {
    font-weight: bold;
    background: #f7faff;
  }
}
.prepay {
  color: #333333;
  ::v-deep ant-form-item-control-wrapper {
    width: 360px;
    height: 44px;
  }
  ::v-deep .ant-input {
    width: 360px;
    height: 44px;
  }
  ::v-deep .ant-input[disabled] {
    background: #f7f7f7;
    border: 1px solid #eaeaea;
  }
  ::v-deep .ant-form-item label {
    color: #333;
  }
  ::v-deep input[type='number']::-webkit-inner-spin-button,
  ::v-deep input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  .footer {
    padding-top: 50px;
    display: flex;
    justify-content: flex-end;
    height: 90px;
    .pay-btn {
      width: 124px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      background: #007ce2;
      border-radius: 5px;
    }
    .cancle-btn {
      margin-left: 20px;
      width: 92px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      border-radius: 5px;
      border: 1px solid #c9d1de;
    }
  }
}
.prepaymodal {
  ::v-deep .ant-form-item label {
    color: #333;
  }
  ::v-deep .ant-modal-title {
    font-weight: bold;
    color: #1d2129;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  }
  ::v-deep .ant-modal-close .anticon svg {
    margin-top: 20px;
  }
  .upload-btn {
    width: 120px;
    height: 32px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid #005bac;
  }
  .entBtn {
    padding: 0 40px;
    justify-content: flex-end;
    display: flex;
    .ant-btn-primary {
      margin-right: 20px;
      width: 88px;
      height: 32px;
      background: #007ce2;
      border-radius: 5px 5px 5px 5px;
    }
    .ant-btn {
      width: 88px;
      height: 32px;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #c9d1de;
    }
  }
  .handle-blok {
    margin: 0 auto;
    width: 130px;
    padding-bottom: 30px;
    text-align: center;
    img {
      width: 50px;
      height: 50px;
      text-align: center;
    }
    .info {
      padding-top: 12px;
      padding-bottom: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .handle-btn {
      width: 88px;
      height: 32px;
      background: #007ce2;
      border-radius: 5px 5px 5px 5px;
    }
  }
}
</style>
